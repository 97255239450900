<template>
  <div>
    <section>
      <AdvanceTable ref="advanceTable" url="erp/vendor-profile" :height="window.height - 310" tableSize="sm" :columns="columns" :baseFilters="{isShowDeleted: isShowDeleted}" v-loading="isLoading" @edit="showProfile">
        <template #button>
          <el-switch
            v-model="isShowDeleted"
            active-text="Show Deleted"
            inactive-text=""
            class="mr-1" @change="reload()">
          </el-switch>

          <b-button variant="info" class="mr-1" @click="openModalForCreate" v-if="getErpCan('VendorProfile.Add')">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{ $t("Vendor Profile") }}</span>
          </b-button>
        </template>
        <template #cell(status)="row">
          <OrganizationProfileStatusTag :item="row.item"/>
        </template>
        <template #cell(actions)="row">
          <span v-if="row.item.status !== 'DELETED'">
            <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)" v-if="getErpCan('VendorProfile.Edit')">{{$t('Edit')}}</el-link>
            <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)" v-if="getErpCan('VendorProfile.Delete')">{{$t('Delete')}}</el-link>
            <el-link icon="el-icon-s-shop" @click="showProfile(row.item)">{{$t('Profile')}}</el-link>
            <el-link icon="el-icon-search" @click="showAudits(row.item)" v-if="getErpCan('VendorProfile.Audits')">{{$t('Audits')}}</el-link>
          </span>
        </template>
      </AdvanceTable>
    </section>

    <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpProfile, getErpCan } from "@/utils/account-localstorage";

import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BButton, BModal } from "bootstrap-vue";
import OrganizationProfileStatusTag from "@/views/erp/components/OrganizationProfileStatusTag.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    OrganizationProfileStatusTag,
    BButton,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "200", filtertype: "input", sortable: true },
        { key: "companyName", modelName: "companyName", label: "Company Name (Invoice)", filtertype: "input", sortable: true },
        { key: "companyNameInternal", modelName: "companyNameInternal", label: "Company Name (System)", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "150", filtertype: "select", options: {'DOCUMENTED': "Documented", 'VERIFIED': "Verified", 'PENDING': "Pending", 'DELETED': 'Deleted'}, sortable: true, align: "center" },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "320" },
      ],
      productList: [],
      isShow: false,
      title: '',
      row: {},

      draftList: [],
      draft: null,

      isShowDeleted: true,
      isLoading: false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    getErpCan,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    openModalForCreate() {
      this.$router.push({
        name: "erp-vendor-profile-create",
        params: {},
      });
    },
    openModalForEdit(row) {
      this.$router.push({
        name: 'erp-vendor-profile-edit',
        params: { id: row.id }
      })
    },
    openModalForDelete(row) {
      this.$confirm('Confirm delete "' + row.companyName + '"?', 'Delete Organization').then(() => {
        this.isLoading = true;
        axios.delete(apis.erpOrganizationProfile + '/' + row.id)
          .then((response) => {
            this.$refs.advanceTable.loadList();
          }).finally(() => {
          this.isLoading = false;
        });
      }).catch(() => {
      });
    },
    showProfile(item) {
      if (item.status === 'DELETED') return;
      this.$router.push({
        name: 'erp-vendor-company-profile',
        params: { id: item.id }
      })
    },

    showAudits(entity) {
      this.$refs.auditTrail.showMultiple([
        {
          className: 'com.kerrylogistics.dashboard.entities.erp.VendorProfile',
          name: 'Organization Profile',
          objectId: entity.id
        },
        {
          className: 'com.kerrylogistics.dashboard.entities.erp.VendorDocument',
          name: 'Organization Document',
          objectId: entity.documents.map(o => o.id)
        }
      ]);
    }
  }
};
</script>
